<template>
  <div class="page-login">
    <c-auth-modal title="Восстановление" :loading="$var('load')" back>
      <template #sub>
        Введите свой телефон, чтобы восстановить пароль от аккаунта
      </template>
      <n-form @submit="submit">
        <n-items>
          <n-input title="Введите телефон" v-bind="$form.input('login')" />
          <n-button wide color="primary" type="submit">Далее</n-button>
        </n-items>
      </n-form>
    </c-auth-modal>
  </div>
</template>

<script>
export default {
  name: 'PageForgot1',
  created() {
    this.$form.init({
      login: '',
    })
    this.$form.rules({
      login: [ 'mask', ],
    })
    this.$form.masks({
      login: 'phone',
    })

    $app.auth.needAuth(false)
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.sendCode(this.$form.get()).then((response) => {
          this.$router.push({ name: 'auth.forgot2', query: this.$form.get(), })
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {

}
</style>
